<template>
  <div class="content">
    <div class="main">
      <section class="productSwiper">
        <div class="productSwiperView">
          <div class="productSwiperBox" v-if="product_swiper.length != 0">
            <div class="leftView">
              <div class="swiperTopView">
                <swiper
                  :options="swiperOptionOne"
                  class="gallery-top"
                  ref="swiper"
                >
                  <swiper-slide
                    class="slide-item"
                    v-for="(item, index) in product_swiper"
                    :key="index"
                  >
                    <img :src="item" alt="" />
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev swiper_btn" slot="button-prev">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="swiper-button-next swiper_btn" slot="button-next">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <!-- swiper2 Thumbs -->
              <div class="galleryThumbsView">
                <div class="swiper-pagination" slot="pagination"></div>
              </div>
            </div>
            <div class="rightView">
              <h4>{{ e_title }}</h4>
              <h4 class="title">{{ title }}</h4>
              <div
                class="partsRightItem"
                v-for="(item, index) in spec_switching"
                :key="index"
              >
                <h5 class="partsTitle">{{ item.name }}</h5>
                <ul class="NotoSansTCLight" :class="index == 1 ? 'column' : ''">
                  <li v-for="(f_item, f_index) in item.children" :key="f_index">
                    {{ f_item.spec_value }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="partsSwiper" v-show="partsList.length != 0">
        <div class="partsSwiperView">
          <!-- swiper1 -->
          <div class="swiperOne">
            <swiper
              class="swiper gallery-top"
              :options="swiperOptionTop"
              ref="swiperTop"
            >
              <swiper-slide
                class="slide-item"
                v-for="(item, index) in partsList"
                :key="index"
              >
                <img :src="item.intro_pic_json" alt="" />
              </swiper-slide>
            </swiper>
          </div>
          <!-- swiper2 Thumbs -->
          <div class="swiperTwo">
            <swiper
              class="swiper gallery-thumbs"
              :options="swiperOptionThumbs"
              ref="swiperThumbs"
            >
              <swiper-slide
                class="slide-item"
                v-for="(item, index) in partsList"
                :key="index"
              >
                <h4>{{ item.intro_title }}</h4>
                <p>{{ item.intro_content }}</p>
              </swiper-slide>
            </swiper>
            <div class="operation">
              <div class="swiper-button-prev swiper_btn" slot="button-prev">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-next swiper_btn" slot="button-next">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="autoHardnessView">
        <div class="linkWrap">
          <div class="item" v-for="(item, index) in linkList" :key="index">
            <router-link
              :to="`/knowledge_base_detailed?id=${item.id}&pid=${item.f_cate_id}&twoId=${item.cate_id}`"
              class="link"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <span>{{ item.create_time }}</span>
                <p>{{ item.title }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <video-dialog
      :show="videoDialogShow"
      :data="videoDialogData"
      @close="videoDialogShow = false"
    />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import hardness from "@/components/hardness";
import autoHardness from "@/components/autoHardness";
import specifications from "@/components/specifications";
import videoDialog from "@/components/video-dialog";
export default {
  name: "PartsDetails",
  components: {
    swiper,
    swiperSlide,
    hardness,
    autoHardness,
    specifications,
    videoDialog,
  },
  data() {
    return {
      id: this.$route.query.id,
      status: this.$route.query.status,

      videoDialogShow: false,
      videoDialogData: {},

      swiperOptionOne: {
        navigation: {
          nextEl: ".productSwiper .swiper-button-next",
          prevEl: ".productSwiper .swiper-button-prev",
        },
        pagination: {
          el: ".productSwiper .swiper-pagination",
          clickable: true,
          renderBullet: (index, className) => {
            return `<div class="paginationItem ${className}">
              <img src="${this.product_swiper[index]}" />
            </div>`;
          },
        },
      },
      product_swiper: [],
      spec_switching: [],

      swiperOptionTop: {
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: ".operation .swiper-button-next",
          prevEl: ".operation .swiper-button-prev",
        },
        pagination: {
          el: ".operation .swiper-pagination",
          clickable: true,
        },
      },
      partsList: [],

      linkList: [],
      e_title: "",
      title: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  mounted() {
    this.getData();
    // this.linkListFun();
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
  methods: {
    /**获取数据 */
    getData() {
      //配件请求
      if (this.status == "1") {
        this.$http.productPartsDetail({ id: this.id }).then((res) => {
          if (res.code == 200) {
            this.e_title = res.data.e_title;
            this.title = res.data.title;
            this.product_swiper = res.data.swiper_json;
            this.spec_switching = res.data.spec_switching;
            this.partsList = res.data.intro_content;
            this.linkList = res.data.relevant_knowledge;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        //零件请求
        this.$http.sparePartsDetail({ id: this.id }).then((res) => {
          if (res.code == 200) {
            this.e_title = res.data.e_title;
            this.title = res.data.title;
            this.product_swiper = res.data.swiper_json;
            this.spec_switching = res.data.spec_switching;
            this.partsList = res.data.relevant;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    /**
     * 首页接口 用来请求最下面的链接跳转
     */
    linkListFun() {
      this.$http.home().then((res) => {
        if (res.code == 200) {
          this.linkList = res.data.index_knowledge;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
@import "@/assets/style/productDetails.scss";
.productSwiper {
  padding-bottom: 40px;
  @include respond-to(lg) {
    padding-bottom: 20px;
  }
}
.title {
  margin-bottom: 10px;
}
.partsRightItem {
  margin-bottom: 20px;
  .partsTitle {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
    // font-weight: 500;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 20px;
      font-size: 14px;
      color: #333333;
      // height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: solid 1px #222224;
      // border-radius: 16px;
      // padding: 0 20px;
    }
    &.column {
      flex-direction: column;
      li {
        justify-content: flex-start;
      }
    }
  }
}
</style>